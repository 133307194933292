.send-email-form {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 800px;
}

.email-form .form-group {
    margin-bottom: 15px;
}

.email-form label {
    display: block;
    margin-bottom: 5px;
}

.email-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    
}
.email-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 300px;
}

.email-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
}

.email-form button:disabled {
    background-color: #ccc;
}

.response-message {
    margin-top: 20px;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 4px;

}

.response-message.success {
    background-color: #28a745;
}

.response-message.error {
    background-color: #dc3545;
}
