.container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.email-section {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.email-conversation {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.mounting-date,
.closure-percent {
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="date"],
input[type="number"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.notes-section {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
}

.notes-section h2 {
  margin-bottom: 10px;
}

.notes-section p {
  margin-bottom: 5px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}