.email-section {
  padding: 20px;
}

.minimize-button {
  margin-bottom: 10px;
}

.client-name {
  font-size: 24px;
  margin-bottom: 5px;
}

.client-number {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.email-thread {
  border-left: 4px solid #4caf50;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
}

.thread-header {
  background-color: #e0e0e0;
  padding: 10px;
  margin-bottom: 10px;
}

.thread-conversations {
  margin-bottom: 10px;
}

.thread-conversation {
  margin-bottom: 10px;
}

.message-content {
  white-space: pre-wrap;
}

.reply-section {
  margin-top: 20px;
}

.reply-section textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  resize: vertical;
}

.reply-section button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}